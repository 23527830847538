export enum GMStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  MANUAL = 'MANUAL_CHECKING',
}
export interface Competition {
  id: string;
  name: string;
  championship: string;
  beginDatetime: string;
  competitionLocation: string;
  organizerTeam: string;
  opponentTeam: string;
  codeRencontre: string;
  nbViewers: number | null;
  nbViewersGm: number | null;
  gmVideoStatus: GMStatus;
  gmStatsStatus: GMStatus;
  gmIsScheduled: boolean;
  statsLink: string | null;
  videoLinks: string[] | null;
  organizerClubs: [
    {
      id: string;
      name: string;
    },
  ];
  competitionIncome: {
    total: number;
    gmCriteriaFulfilled: boolean;
    isGmIncomeIncluded: boolean;
    gmIncome: number;
  } | null;
  fansIncomes: [{ total: number }];
}

export interface TestCompetition {
  id: string;
  name: string;
  beginDatetime: string;
  codeRencontre: string;
  channels: {
    id: string;
    name: string;
    ingestUrl: string;
  }[];
}

export interface Championship {
  id: string;
  name: string;
  sport: string;
  associatedTeams?: { id: string; name: string; publicName: string; club: string }[];
}

export interface CompetitionLocation {
  id: string;
  name: string;
  address: {
    id: string;
    street: string;
    city: string;
    zipcode: number;
    country: string;
  };
}
