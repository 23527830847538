import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { MouseEventHandler, useCallback } from 'react';
import {
  ButtonProps,
  DeleteButton,
  Identifier,
  RaRecord,
  RedirectionSideEffect,
  SaveButton,
  Toolbar,
  useRedirect,
  useTranslate,
} from 'react-admin';
import BackIcon from '@mui/icons-material/ArrowBack';

const PREFIX = 'RaBackButton';

const StyledButton = styled(Button, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }: any) => ({
  position: 'relative',
  [`& .MuiSvgIcon-root, & .MuiIcon-root, & .MuiCircularProgress-root`]: {
    marginRight: theme.spacing(1),
  },
  [`& .MuiSvgIcon-root, & .MuiIcon-root`]: {
    fontSize: 18,
  },
}));

export interface BackButtonProps {
  redirectTo: RedirectionSideEffect;
  resource: string;
  id?: Identifier;
  data?: Partial<RaRecord>;
  state?: object;
}

const BackButton = (props: ButtonProps & BackButtonProps) => {
  const {
    color = 'primary',
    label = 'ra.action.save',
    onClick,
    disabled: disabledProp,
    type = 'submit',
    variant = 'contained',
    redirectTo,
    resource,
    id,
    data,
    state = {},
    ...rest
  } = props;

  const translate = useTranslate();
  const redirect = useRedirect();

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      if (onClick) {
        onClick(event);
      }
      if (event.defaultPrevented) {
        return;
      }
      redirect(redirectTo, resource, id, data, state);
    },
    [onClick, redirect, redirectTo, resource, id, data, state],
  );

  const displayedLabel = label && translate(label, { _: label });

  return (
    <StyledButton
      variant={variant}
      type={type}
      color={color}
      aria-label={displayedLabel}
      onClick={handleClick}
      {...rest}
    >
      {displayedLabel}
    </StyledButton>
  );
};

export const SimpleFormToolbar = ({
  withDelete = true,
  ...props
}: { withDelete?: boolean } & ButtonProps & BackButtonProps) => (
  <Toolbar style={{ justifyContent: 'space-around' }}>
    <BackButton {...props} label='Annuler' variant='outlined' startIcon={<BackIcon />} />
    <SaveButton />
    {withDelete && (
      <DeleteButton
        mutationMode='pessimistic'
        confirmTitle=''
        sx={{ width: '120px', height: '36px', color: 'white', gap: 1 }}
      />
    )}
  </Toolbar>
);
