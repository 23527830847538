export enum IncomeStatus {
  ESTIMATED = 'ESTIMATED',
  SENT_TO_BILLING = 'SENT_TO_BILLING',
  PAID = 'PAID',
}

export interface CompetitionIncome {
  id: string;
  status: IncomeStatus;
  total: number;
  competition: { name: string; beginDatetime: string; endDatetime?: string; gm_is_scheduled: boolean };
  gmIncome?: number;
  gmCriteriaFulfilled?: boolean;
  isGmIncomeIncluded?: boolean;
  videoIncome?: number;
  videoCriteriaFulfilled?: boolean;
  statsIncome?: number;
  statsCriteriaFulfilled?: boolean;
  fansIncome?: number;
  ownerIncome?: number;
  organizerIncome?: number;
  isOrganizerIncomeIncluded?: boolean;
}

export interface FansIncome {
  id: string;
  status: IncomeStatus;
  total: number;
  startDatetime: string;
  endDatetime: string;
}

export interface ReferralIncome {
  id: string;
  status: IncomeStatus;
  total: number;
  startDatetime: string;
  endDatetime: string;
  affiliateName: string;
  campaignName: string;
  campaignEndDatetime: string;
  nbImpressions: number;
}
