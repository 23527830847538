export enum ValidationStatus {
  NOT_VALIDATED = 'NOT_VALIDATED',
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED',
  REFUSED = 'REFUSED',
  BANNED = 'BANNED',
}

export interface Club {
  id: string;
  name: string;
  association: string;
  sport: string;
  address: {
    id: string;
    street: string;
    city: string;
    zipcode: number;
    country: string;
  };
  socialNetworkUrl: string | null;
  websiteUrl: string | null;
  validationStatus: ValidationStatus;
  statsCode: string;
  //referral
  referralName: string;
  referralFunction: string;
  referralPhoneNumber: string;
  referralEmail: string;
  // substitute
  substituteName: string | null;
  substituteFunction: string | null;
  substitutePhoneNumber: string | null;
  substituteEmail: string | null;
}
