export const getModifiedKeys = (previousObj: any, newObj: any): any => {
  const modifiedKeys: any = {};

  for (const key in previousObj) {
    if (previousObj[key] !== newObj[key]) {
      modifiedKeys[key] = newObj[key];
    }
  }

  return modifiedKeys;
};

export const roundNumber2decimal = (number: number) => {
  return Math.round(number * 100) / 100;
};

export const dateFormatDDMMAA = (date: Date) => {
  return date.toLocaleDateString('FR-fr').slice(0, 10);
}
