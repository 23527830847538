import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  Create,
  useRedirect,
  useCreate,
  useNotify,
  useUpdate,
  useRecordContext,
  SelectInput,
  DateField,
  DateTimeInput,
  useGetList,
  ReferenceInput,
  FunctionField,
  useTranslate,
  FormDataConsumer,
  DeleteButton,
  useGetOne,
  Button,
} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import { SimpleFormToolbar } from '../layout/SimpleFormToolbar';
import { useResourcePath } from '../services/hooks';
import './FormStyle.scss';
import './CompetitionStyle.scss';
import DownloadIcon from '@mui/icons-material/Download';
import ReplyIcon from '@mui/icons-material/Reply';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Checkbox from '@mui/material/Checkbox';
import Empty, { EmptyCategoryEnum } from '../layout/Empty';
import { Championship, Competition, GMStatus } from '../models/competition';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { roundNumber2decimal } from '../utils/utils';

const CompetitionsListChampionshipItem = ({ record }: { record: Competition }) => {
  const { data: championship } = useGetOne<Championship>('championships', {
    id: record.championship,
    meta: { useResourcePath: false },
  });
  return <span>{championship?.name}</span>;
};

export const CompetitionsList = () => {
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [filesToDownload, setFilesToDownload] = useState<{ statFile: string | null; videoFiles: string[] | null }>();

  const resourcePath = useResourcePath();
  const notify = useNotify();

  const clubId = resourcePath.resourcePath.value[0].id;
  const today = new Date();

  const MatchDetails = () => {
    const record: Competition = useRecordContext();

    const handleGmStatus = () => {
      if (record.gmVideoStatus === GMStatus.ACCEPTED && record.gmStatsStatus === GMStatus.ACCEPTED) {
        return GMStatus.ACCEPTED;
      } else if (record.gmVideoStatus === GMStatus.REFUSED || record.gmStatsStatus === GMStatus.REFUSED) {
        return GMStatus.REFUSED;
      } else return GMStatus.NOT_CHECKED;
    };
    const gmStatus = handleGmStatus();

    const matchDate = new Date(record.beginDatetime);
    const noData = matchDate > today ? "Votre match n'a pas encore été diffusé" : 'Information bientôt disponible';

    return (
      <div className='detailsContainer'>
        <section className='detailsCard'>
          <div className='cardTitle'>
            <p>Total Viewers</p>
          </div>
          {record.nbViewers ? (
            <h4>{record.nbViewers}</h4>
          ) : (
            <p>
              <em>{noData}</em>
            </p>
          )}
        </section>
        <section className='detailsCard'>
          <div className='cardTitle'>
            <p>Viewers restés plus de 10 min</p>
          </div>
          {record.nbViewersGm ? (
            <h4>{record.nbViewersGm}</h4>
          ) : (
            <p>
              <em>{noData}</em>
            </p>
          )}
        </section>

        {clubId === record.organizerClubs[0].id && (
          <section className='detailsCard'>
            <div className='cardTitle'>
              <p>Conditions pour le RMG</p>
            </div>
            {record.competitionIncome ? (
              record.competitionIncome.gmIncome ? (
                <div>
                  <div className='checkboxContainer'>
                    <Checkbox
                      defaultChecked={true}
                      checkedIcon={record.gmIsScheduled ? <CheckBoxIcon /> : <DisabledByDefaultIcon />}
                      sx={{
                        height: 5,
                        paddingLeft: 0,
                        '&.Mui-checked': {
                          color: record.gmIsScheduled ? '#64dcbe' : 'red',
                        },
                      }}
                    />
                    <p style={{ color: record.gmIsScheduled ? '#64dcbe' : 'red' }}>Programmé à l&apos;avance</p>
                  </div>
                  <div className='checkboxContainer'>
                    <Checkbox
                      defaultChecked={gmStatus === GMStatus.ACCEPTED || gmStatus === GMStatus.REFUSED ? true : false}
                      checkedIcon={gmStatus === GMStatus.REFUSED ? <DisabledByDefaultIcon /> : <CheckBoxIcon />}
                      sx={{
                        height: 5,
                        paddingLeft: 0,
                        color: 'white',
                        '&.Mui-checked': {
                          color: gmStatus === GMStatus.REFUSED ? 'red' : '#64dcbe',
                        },
                      }}
                    />
                    <p
                      style={{
                        color:
                          gmStatus === GMStatus.ACCEPTED ? '#64dcbe' : gmStatus === GMStatus.REFUSED ? 'red' : 'white',
                      }}
                    >
                      Diffusion du match avec stats
                    </p>
                  </div>
                </div>
              ) : (
                <p>
                  <em>Non concerné</em>
                </p>
              )
            ) : (
              <p>
                <em>{noData}</em>
              </p>
            )}
          </section>
        )}

        {clubId === record.organizerClubs[0].id && (
          <section className='detailsCard'>
            <div className='cardTitle'>
              <p>Revenu Minimum Garanti (HT)</p>
            </div>

            {record.competitionIncome ? (
              record.competitionIncome.gmIncome ? (
                <h4
                  style={{
                    color: !record.competitionIncome.gmCriteriaFulfilled
                      ? 'red'
                      : record.competitionIncome.isGmIncomeIncluded
                      ? '#64dcbe'
                      : 'white',
                    textDecoration: !record.competitionIncome.isGmIncomeIncluded ? 'line-through' : undefined,
                  }}
                >
                  {roundNumber2decimal(record.competitionIncome.gmIncome)} €
                </h4>
              ) : (
                <p>
                  <em>Non concerné</em>
                </p>
              )
            ) : (
              <p>
                <em>{noData}</em>
              </p>
            )}
          </section>
        )}

        {clubId === record.organizerClubs[0].id && (
          <section className='detailsCard'>
            <div className='cardTitle'>
              <p>Votre revenu (inclus RMG ou Orga, en HT)</p>
            </div>
            {record.competitionIncome ? (
              <h4>{roundNumber2decimal(record.competitionIncome.total)} €</h4>
            ) : (
              <p>
                <em>{noData}</em>
              </p>
            )}
          </section>
        )}

        {clubId !== record.organizerClubs[0].id && (
          <section className='detailsCard'>
            <div className='cardTitle'>
              <p>Votre revenu (supporteurs)</p>
            </div>
            {record.fansIncomes.length > 0 ? (
              <h4>{roundNumber2decimal(record.fansIncomes.reduce((acc, fanIncome) => acc + fanIncome.total, 0))} €</h4>
            ) : (
              <p>
                <em>{noData}</em>
              </p>
            )}
          </section>
        )}
      </div>
    );
  };

  return (
    <List exporter={false} pagination={false} empty={<Empty category={EmptyCategoryEnum.COMPETITION} />}>
      <>
        <Dialog open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
          <div className='downloadModal'>
            <h3 style={{ marginBottom: '10px' }}>Télécharger</h3>
            <a
              href={filesToDownload?.statFile ? filesToDownload?.statFile : '#'}
              download
              className={filesToDownload?.statFile ? 'download' : 'download disabled'}
            >
              Stats
            </a>
            {!filesToDownload?.statFile && (
              <p>
                <em>disponible prochainement</em>
              </p>
            )}

            {filesToDownload?.videoFiles ? (
              <>
                {filesToDownload?.videoFiles.map((video, index) => (
                  <a href={video} download key={index} className='download' target='_blank' rel='noreferrer'>
                    Vidéo {index + 1}
                  </a>
                ))}
              </>
            ) : (
              <>
                <button className='download disabled' disabled={true}>
                  Vidéo
                </button>
                <p>
                  <em>disponible prochainement</em>
                </p>
              </>
            )}
          </div>
        </Dialog>

        <div style={{ padding: '10px 50px', fontSize: 14, backgroundColor: '#121212', textAlign: 'center' }}>
          <p>
            <b>Attention</b>, les Matchs que vous créez ici seront visibles dans l&#39;Application Mobile et dans la
            Régie Pub pour générer des revenus publicitaires. Si vous voulez tester le portail partenaire, la saisie de
            statistiques et visualiser le résultat sur l&#39;application mobile, merci de vous rendre dans l&#39;onglet{' '}
            <Link to='/test_competitions' style={{ textDecoration: 'none', color: '#64DCBE' }}>
              Matchs de test
            </Link>
            .
          </p>
        </div>

        <Datagrid expand={<MatchDetails />} bulkActionButtons={false}>
          <TextField source='name' label='Match' sortable={false} />
          <FunctionField
            label='Championnat'
            render={(record: Competition) => <CompetitionsListChampionshipItem record={record} />}
          />
          <DateField source='beginDatetime' label='Date de début' sortable={false} />
          <TextField source='codeRencontre' label='Code rencontre' sortable={false} />
          <FunctionField
            label='Organisateur'
            render={(record: Competition) => (clubId === record.organizerClubs[0].id ? 'Votre club' : 'Club adverse')}
          />

          <FunctionField
            label={"Redirection  \nvers l'évènement"}
            textAlign='center'
            render={(record: Competition) => {
              const compDate = new Date(record.beginDatetime);
              const limitDate = new Date(compDate.setHours(compDate.getHours() + 2));

              return (
                <>
                  <a
                    href={`https://api.champs.fr/alpha/qr/${record.codeRencontre}`}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      textDecoration: 'none',
                      pointerEvents: limitDate < today ? 'none' : 'auto',
                    }}
                  >
                    <Button
                      label=''
                      color='primary'
                      sx={{ width: '27px', minWidth: '1px' }}
                      disabled={limitDate < today}
                    >
                      <QrCode2Icon />
                    </Button>
                  </a>

                  <Button
                    label=''
                    color='primary'
                    sx={{ width: '27px', minWidth: '1px', transform: 'scaleX(-1)' }}
                    onClick={() => {
                      navigator.clipboard.writeText(`https://api.champs.fr/alpha/live/${record.codeRencontre}`);
                      notify('Le lien a bien été copiée dans votre presse papier.', { type: 'success' });
                    }}
                    disabled={limitDate < today}
                  >
                    <ReplyIcon />
                  </Button>
                </>
              );
            }}
          />

          <FunctionField
            render={(record: Competition) =>
              new Date(record.beginDatetime) > today ? (
                <EditButton disabled={clubId !== record.organizerClubs[0].id} />
              ) : (
                <Button
                  label='télécharger'
                  color='primary'
                  sx={{ fontSize: '12px', fontWeight: 'bold', padding: '3px' }}
                  onClick={() => {
                    setShowDownloadModal(true);
                    setFilesToDownload({ statFile: record.statsLink, videoFiles: record.videoLinks });
                  }}
                >
                  <DownloadIcon />
                </Button>
              )
            }
          />

          <FunctionField
            render={(record: Competition) => (
              <DeleteButton
                label=''
                mutationMode='pessimistic'
                confirmTitle=''
                disabled={new Date(record.beginDatetime) < today || clubId !== record.organizerClubs[0].id}
              />
            )}
          />
        </Datagrid>
      </>
    </List>
  );
};

interface CompetitionFormProps {
  type: 'create' | 'edit';
}

const CompetitionsForm = ({ type }: CompetitionFormProps) => {
  const resourcePath = useResourcePath();
  const redirect = useRedirect();
  const notify = useNotify();

  const translate = useTranslate();

  const [create] = useCreate();
  const [update] = useUpdate();

  const minDate = new Date().toISOString().slice(0, 16);

  // Get championships for the sport corresponding to the club
  const clubId = resourcePath.resourcePath.value[0].id as string;
  const { data: championships } = useGetList<Championship>(
    'championships/with_associated_teams',
    {
      meta: { useResourcePath: false },
      filter: {
        club: clubId,
        with_assigned_teams: 'true',
      },
    },
    {
      enabled: !!clubId,
    },
  );

  const handleTeamsChoices = (selectedChampionshipId: string, organizerTeam: boolean) => {
    const associatedTeams = championships?.find((champ) => champ.id === selectedChampionshipId)?.associatedTeams;

    if (organizerTeam) {
      return associatedTeams?.filter((associatedTeam) => associatedTeam.club === clubId) || [];
    } else return associatedTeams?.filter((associatedTeam) => associatedTeam.club !== clubId) || [];
  };

  const save = useCallback(
    async (values: any) => {
      try {
        if (type == 'create') {
          const associatedTeams = championships?.find((champ) => champ.id === values.championship)?.associatedTeams;
          const homeTeam = associatedTeams?.find((at) => at.id === values.organizerTeam)?.publicName;
          const awayTeam = associatedTeams?.find((at) => at.id === values.opponentTeam)?.publicName;
          const newData = { ...values, name: `${homeTeam} / ${awayTeam}` };
          await create('competitions', { data: newData }, { returnPromise: true });
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        } else {
          const newData = { ...values };
          await update('competitions', { id: values.id, data: newData }, { returnPromise: true });
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        }
        redirect('list', 'competitions');
      } catch (error: any) {
        if (error.body) {
          // The shape of the returned validation errors must match the shape of the form
          for (const key in error.body) {
            error.body[key] = translate(error.body[key]);
          }
          return error.body;
        }
      }
    },
    [championships, create, notify, redirect, translate, type, update],
  );

  return (
    <SimpleForm onSubmit={save} toolbar={<SimpleFormToolbar redirectTo='list' resource='competitions' />}>
      {type == 'edit' ? (
        <div className='formContainer'>
          <TextInput
            source='name'
            label='Match'
            style={{ marginRight: '20px', minWidth: '50%', flexDirection: 'column' }}
            isRequired
            parse={(v: string) => v}
            disabled
          />
        </div>
      ) : null}
      <div className='formContainer'>
        <DateTimeInput
          source='beginDatetime'
          label='Date et heure de début'
          style={{ marginRight: '20px' }}
          inputProps={{
            min: minDate,
          }}
          isRequired
        />
        <ReferenceInput source='competitionLocation' reference='competition_locations' label='Lieu de compétition'>
          <SelectInput
            emptyValue={''}
            label='Lieu de compétition'
            optionText='name'
            source='competitionLocation'
            style={{ marginRight: '20px', width: '400px' }}
            isRequired
          />
        </ReferenceInput>
      </div>
      <div className='formContainer'>
        <SelectInput
          label='Championnat'
          source='championship'
          choices={championships || []}
          style={{ marginRight: '20px', width: '420px' }}
          isRequired
          disabled={type == 'edit'}
        />
      </div>

      <div className='formContainer'>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <SelectInput
                choices={handleTeamsChoices(formData.championship, true)}
                label='Votre équipe'
                source='organizerTeam'
                optionValue='id'
                optionText={(record) => `${record.publicName}`}
                style={{ marginRight: '20px' }}
                sx={{
                  '& .MuiChip-root': {
                    height: '22px',
                    marginTop: '-1px',
                  },
                }}
                helperText='Sélectionnez votre propre équipe. Seules les équipes qui vous ont été assignées et liées au championnat sélectionné apparaissent ici.'
                isRequired
                disabled={type == 'edit'}
                {...rest}
              />
              <SelectInput
                choices={handleTeamsChoices(formData.championship, false)}
                label='Équipe adverse'
                source='opponentTeam'
                optionValue='id'
                optionText={(record) => `${record.publicName}`}
                style={{ marginRight: '20px' }}
                sx={{
                  '& .MuiChip-root': {
                    height: '22px',
                    marginTop: '-1px',
                  },
                }}
                helperText="Sélectionnez l'équipe adverse. Seules les équipes liées au championnat sélectionné apparaissent ici."
                isRequired
                disabled={type == 'edit'}
                {...rest}
              />
            </>
          )}
        </FormDataConsumer>
      </div>
      <div className='formContainer'>
        <TextInput
          source='codeRencontre'
          label='Code rencontre'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
          disabled={type == 'edit'}
          helperText='Votre ID match. Saisie libre. le code doit être unique.'
          placeholder='Ex : PSGB03'
        />
      </div>
    </SimpleForm>
  );
};

export const CompetitionsEdit = () => {
  return (
    <Edit>
      <CompetitionsForm type='edit' />
    </Edit>
  );
};

export const CompetitionsCreate = () => {
  return (
    <Create>
      <CompetitionsForm type='create' />
    </Create>
  );
};
