import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import { Tooltip } from '@mui/material';
import { Datagrid, FunctionField, List, NumberField, TextField, useDataProvider, useGetOne } from 'react-admin';
import { useQuery } from 'react-query';
import './IncomesStyle.scss';
import { Bill } from '../models/bill';
import { CompetitionIncome, FansIncome, IncomeStatus, ReferralIncome } from '../models/incomes';
import { useResourcePath } from '../services/hooks';
import { dateFormatDDMMAA, roundNumber2decimal } from '../utils/utils';
import { FunctionalLogs } from '../models/functionalLogs';
import { ReactComponent as InfoGray } from '../layout/infoGray.svg';

export enum TabIncomeNames {
  COMPETITIONS = 'COMPETITIONS',
  FANS = 'FANS',
  REFERRAL = 'REFERRAL',
}

const EmptyIncome = () => <p className='empty'>Vous n&#39;avez pas encore généré de revenu dans cette catégorie</p>;

export default function IncomesList() {
  const resourcePath = useResourcePath();
  const clubId = resourcePath.resourcePath.value[0].id || '';

  const dataProvider = useDataProvider();

  const { data: totalIncome } = useQuery<number>(['getTotalIncome'], () => dataProvider.getTotalIncome(clubId));

  const { data: pendingIncome } = useQuery<number>(['getPendingIncome'], () => dataProvider.getPendingIncome(clubId));

  // retry false to avoid chain refetch when no bills exist = error 404
  const { data: latestBill } = useGetOne<Bill>('bills/latest', { id: '' }, { retry: false });

  // retry false to avoid chain refetch when no functional logs exist = error 404
  const { data: functionalLogs } = useGetOne<FunctionalLogs>(
    'functional_logs',
    { meta: { useResourcePath: false }, id: '' },
    { retry: false },
  );

  const [incomeSelected, setIncomeSelected] = useState<string>(TabIncomeNames.COMPETITIONS);
  const handleIncomeSelection = (event: React.SyntheticEvent, newValue: string) => {
    setIncomeSelected(newValue);
  };

  const StyledTab = styled(Tab)({
    color: 'white',
    '&:hover': { filter: 'brightness(0.8)' },
  });

  const labelStatus = () => {
    if (functionalLogs) {
      const adIncomesSavedUntilDate = new Date(functionalLogs.adIncomesSavedUntil);
      // Because Periode is displayed in UTC too
      const adIncomesSavedUntilUTC = new Date(
        adIncomesSavedUntilDate.getUTCFullYear(),
        adIncomesSavedUntilDate.getUTCMonth(),
        adIncomesSavedUntilDate.getUTCDate(),
      );
      return (
        <div>
          <div>Status</div>
          <div>(Au {dateFormatDDMMAA(adIncomesSavedUntilUTC)} inclus)</div>
        </div>
      );
    } else {
      return <div>Status</div>;
    }
  };

  return (
    <div style={{ padding: '30px' }}>
      <h3 style={{ color: '#64dcbe' }}>Vos Revenus</h3>
      <p className='incomeDescription'>
        Vous percevrez des revenus publicitaires sur chaque publicité si : un de vos supporters la voit, la publicité
        est diffusée sur un match que vous filmez ou la publicité est commandée par un annonceur partenaire de votre
        club. <br />
        Pour vous assurer un revenu en cas de publicité insuffisante, nous garantissons le
        <b> Revenu Minimum Garanti (RMG)</b>
        , reversé uniquement s&#39;il est supérieur aux revenus organisateur. Ce revenu est de 0,20€ par supporter
        connecté plus de 10min sur vos matchs. Pour en bénéficier, il vous suffit de programmer vos matchs dans les 60
        jours à compter de la date de création de votre équipe, et de diffuser vos matchs avec la saisie des
        statistiques en Live.
        <br />
        <br />
        Les revenus vous sont versés automatiquement chaque mois si le montant généré est supérieur à 100€. Sinon, ces
        revenus seront basculés sur le mois d&#39;après. A la fin de la saison, les revenus restants seront tous versés
        peu importe le montant total. Pour les percevoir, il vous faut <b>remplir le mandat d’autofacture</b> et{' '}
        <b>renseigner le RIB du club</b> dans l’onglet{' '}
        <Link to='/billing' style={{ textDecoration: 'none', color: '#64DCBE' }}>
          Facturation
        </Link>
        .
      </p>

      <section className='bubblesContainer'>
        <article className='bubble'>
          <p>Revenus cumulés</p>
          <h4>{totalIncome !== undefined ? roundNumber2decimal(totalIncome) + ' €' : '-'}</h4>
        </article>
        <article className='bubble'>
          <p>Dernier versement</p>
          <h4>{latestBill ? roundNumber2decimal(latestBill.amount) + ' €' : '-'} </h4>
        </article>
        <article className='bubble'>
          <p>Versements en attente</p>
          <h4>{pendingIncome !== undefined ? roundNumber2decimal(pendingIncome) + ' €' : '-'}</h4>
        </article>
      </section>

      <Tabs
        sx={{ fontFamily: 'RobotoBold', borderBottom: '1px solid grey' }}
        onChange={handleIncomeSelection}
        centered
        aria-label='incomeTypes'
        value={incomeSelected}
      >
        <StyledTab label='Evènements organisés' value={TabIncomeNames.COMPETITIONS} />
        <StyledTab label='Revenus supporters' value={TabIncomeNames.FANS} />
        <StyledTab label="Apporteurs d'affaires" value={TabIncomeNames.REFERRAL} />
      </Tabs>

      {incomeSelected === TabIncomeNames.COMPETITIONS && (
        <List
          resource='competition_incomes'
          empty={<EmptyIncome />}
          exporter={false}
          pagination={false}
          sx={{ '& .MuiToolbar-root': { display: 'none' }, '&.RaListToolbar-root': { display: 'none' } }}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source='competition.name' label='Evènements' textAlign='center' sortable={false} />
            <FunctionField
              source='competition.beginDatetime'
              label='Dates de diffusion'
              textAlign='center'
              sortable={false}
              render={(record: CompetitionIncome) => (
                <div>
                  {dateFormatDDMMAA(new Date(record.competition.beginDatetime)) +
                    (record.competition.endDatetime
                      ? ' - ' + dateFormatDDMMAA(new Date(record.competition.endDatetime))
                      : '')}
                </div>
              )}
            />
            <FunctionField
              sortable={false}
              source='organizerIncome'
              label={
                <div>
                  Revenus Organisateur{' '}
                  <Tooltip title={'Survoler un montant pour détails.'} placement='top-end'>
                    <InfoGray height={14} />
                  </Tooltip>
                </div>
              }
              textAlign='center'
              render={(record: CompetitionIncome) =>
                record.organizerIncome ? (
                  <Tooltip
                    title={
                      <div>
                        <div style={{ color: record.videoCriteriaFulfilled !== false ? 'white' : 'red' }}>
                          Part vidéo = {record.videoIncome ? roundNumber2decimal(record.videoIncome) : '-'}
                        </div>
                        <div style={{ color: record.statsCriteriaFulfilled !== false ? 'white' : 'red' }}>
                          Part stats = {record.statsIncome ? roundNumber2decimal(record.statsIncome) : '-'}
                        </div>
                        <div>Part supporters = {record.fansIncome ? roundNumber2decimal(record.fansIncome) : '-'}</div>
                      </div>
                    }
                    placement='bottom-end'
                  >
                    <div
                      style={{
                        textDecorationLine:
                          !record.isOrganizerIncomeIncluded && record.organizerIncome ? 'line-through' : 'none',
                      }}
                    >
                      {record.organizerIncome ? roundNumber2decimal(record.organizerIncome) + ' €' : '-'}
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title='Non concerné' placement='bottom-end'>
                    <div>-</div>
                  </Tooltip>
                )
              }
            />
            <FunctionField
              sortable={false}
              source='gmIncome'
              label={
                <div>
                  RMG{' '}
                  <Tooltip
                    title={'Revenu Minimum Garanti si éligible (cf CGS). Survoler un montant pour détails.'}
                    placement='top-end'
                  >
                    <InfoGray height={14} />
                  </Tooltip>
                </div>
              }
              textAlign='center'
              render={(record: CompetitionIncome) =>
                record.gmIncome ? (
                  <Tooltip
                    title={
                      <div>
                        <div style={{ color: record.videoCriteriaFulfilled ? 'white' : 'red' }}>Vidéo diffusée</div>
                        <div style={{ color: record.statsCriteriaFulfilled ? 'white' : 'red' }}>Stats saisies</div>
                        <div style={{ color: record.competition.gm_is_scheduled ? 'white' : 'red' }}>
                          Programmé à l&apos;avance
                        </div>
                      </div>
                    }
                    placement='bottom-end'
                  >
                    <div
                      style={{
                        textDecorationLine: !record.isGmIncomeIncluded ? 'line-through' : 'none',
                        color: !record.gmCriteriaFulfilled ? 'red' : 'white',
                      }}
                    >
                      {roundNumber2decimal(record.gmIncome) + ' €'}
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title='Non concerné' placement='bottom-end'>
                    <div>-</div>
                  </Tooltip>
                )
              }
            />
            <FunctionField
              sortable={false}
              source='ownerIncome'
              label={
                <div>
                  Revenus Propriétaire{' '}
                  <Tooltip
                    title={"Part destinée au propriétaire de l'évènement (clubs, fédération, ..., cf CGS)."}
                    placement='top-end'
                  >
                    <InfoGray height={14} />
                  </Tooltip>
                </div>
              }
              textAlign='center'
              render={(record: CompetitionIncome) =>
                record.ownerIncome ? roundNumber2decimal(record.ownerIncome) + ' €' : '-'
              }
            />
            <FunctionField
              source='total'
              label={
                <div>
                  Vos Revenus{' '}
                  <Tooltip title={'Revenu total. Inclus revenus orga si > au RMG, ou RMG si > aux revenus orga.'} placement='top-end'>
                    <InfoGray height={14} />
                  </Tooltip>
                </div>
              }
              textAlign='center'
              sortable={false}
              render={(record: FansIncome) =>
                record.status === IncomeStatus.ESTIMATED ? (
                  <div>{roundNumber2decimal(record.total)}</div>
                ) : (
                  <div style={{ color: '#64dcbe' }}>{roundNumber2decimal(record.total)}</div>
                )
              }
            />
            <FunctionField
              sortable={false}
              source='status'
              textAlign='center'
              label='Status'
              render={(record: CompetitionIncome) =>
                record.status === IncomeStatus.ESTIMATED ? (
                  <div>Estimatif à date</div>
                ) : record.status === IncomeStatus.SENT_TO_BILLING ? (
                  <div>Versement en attente</div>
                ) : (
                  <div style={{ color: '#64dcbe' }}>Versé</div>
                )
              }
            />
          </Datagrid>
        </List>
      )}

      {incomeSelected === TabIncomeNames.FANS && (
        <List
          resource='fans_incomes'
          empty={<EmptyIncome />}
          exporter={false}
          pagination={false}
          sx={{ '& .MuiToolbar-root': { display: 'none' }, '&.RaListToolbar-root': { display: 'none' } }}
        >
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              source='beginDatetime'
              label='Période'
              textAlign='center'
              sortable={false}
              render={(record: FansIncome) => {
                const startDate = new Date(record.startDatetime);
                const endDate = new Date(record.endDatetime);
                // To have periode (example) "01/03/2024 - 31/03/2024" instead of "01/03/2024 - 01/04/2024"
                const utcStartDate = new Date(
                  startDate.getUTCFullYear(),
                  startDate.getUTCMonth(),
                  startDate.getUTCDate(),
                );
                const utcEndDate = new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate());
                return (
                  <div>
                    {dateFormatDDMMAA(utcStartDate) + (record.endDatetime ? ' - ' + dateFormatDDMMAA(utcEndDate) : '')}
                  </div>
                );
              }}
            />
            <FunctionField
              source='total'
              label='Vos Revenus'
              textAlign='center'
              sortable={false}
              render={(record: FansIncome) =>
                record.status === IncomeStatus.ESTIMATED ? (
                  <div>{roundNumber2decimal(record.total)}</div>
                ) : (
                  <div style={{ color: '#64dcbe' }}>{roundNumber2decimal(record.total)}</div>
                )
              }
            />
            <FunctionField
              sortable={false}
              source='status'
              textAlign='center'
              label={labelStatus()}
              render={(record: FansIncome) =>
                record.status === IncomeStatus.ESTIMATED ? (
                  <div>Estimatif à date</div>
                ) : record.status === IncomeStatus.SENT_TO_BILLING ? (
                  <div>Versement en attente</div>
                ) : (
                  <div style={{ color: '#64dcbe' }}>Versé</div>
                )
              }
            />
          </Datagrid>
        </List>
      )}

      {incomeSelected === 'REFERRAL' && (
        <List
          resource='referral_incomes'
          empty={<EmptyIncome />}
          exporter={false}
          pagination={false}
          sx={{ '& .MuiToolbar-root': { display: 'none' }, '&.RaListToolbar-root': { display: 'none' } }}
        >
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              source='beginDatetime'
              label='Période'
              textAlign='center'
              sortable={false}
              render={(record: ReferralIncome) => {
                const startDate = new Date(record.startDatetime);
                const endDate = new Date(record.endDatetime);
                // To have periode (example) "01/03/2024 - 31/03/2024" instead of "01/03/2024 - 01/04/2024"
                const utcStartDate = new Date(
                  startDate.getUTCFullYear(),
                  startDate.getUTCMonth(),
                  startDate.getUTCDate(),
                );
                const utcEndDate = new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate());
                return (
                  <div>
                    {dateFormatDDMMAA(utcStartDate) + (record.endDatetime ? ' - ' + dateFormatDDMMAA(utcEndDate) : '')}
                  </div>
                );
              }}
            />
            <TextField source='affiliateName' label='Partenaire' textAlign='center' sortable={false} />
            <TextField source='campaignName' label='Nom de campagne' textAlign='center' sortable={false} />
            <FunctionField
              source='campaignEndDatetime'
              label='Fin de campagne'
              textAlign='center'
              sortable={false}
              render={(record: ReferralIncome) => <div>{dateFormatDDMMAA(new Date(record.campaignEndDatetime))}</div>}
            />
            <NumberField
              source='nbImpressions'
              label={
                <div>
                  <div>Impressions diffusées</div>
                  <div>(sur période)</div>
                </div>
              }
              textAlign='center'
              sortable={false}
            />
            <FunctionField
              source='total'
              label='Vos Revenus'
              textAlign='center'
              sortable={false}
              render={(record: ReferralIncome) =>
                record.status === IncomeStatus.ESTIMATED ? (
                  <div>{roundNumber2decimal(record.total)}</div>
                ) : (
                  <div style={{ color: '#64dcbe' }}>{roundNumber2decimal(record.total)}</div>
                )
              }
            />
            <FunctionField
              sortable={false}
              source='status'
              textAlign='center'
              label={labelStatus()}
              render={(record: ReferralIncome) =>
                record.status === IncomeStatus.ESTIMATED ? (
                  <div>Estimatif à date</div>
                ) : record.status === IncomeStatus.SENT_TO_BILLING ? (
                  <div>Versement en attente</div>
                ) : (
                  <div style={{ color: '#64dcbe' }}>Versé</div>
                )
              }
            />
          </Datagrid>
        </List>
      )}
    </div>
  );
}
