import React, { useCallback } from 'react';
import {
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  Create,
  useRedirect,
  useCreate,
  useNotify,
  useUpdate,
  FunctionField,
  DeleteButton,
} from 'react-admin';
import { SimpleFormToolbar } from '../layout/SimpleFormToolbar';
import './FormStyle.scss';
import Empty, { EmptyCategoryEnum } from '../layout/Empty';
import { CompetitionLocation } from '../models/competition';

export const CompetitionLocationsList = () => {
  return (
    <List exporter={false} pagination={false} empty={<Empty category={EmptyCategoryEnum.LOCATION} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source='name' label='Nom' sortable={false} />
        <FunctionField
          render={(record: CompetitionLocation) => `${record.address.street}, ${record.address.city}`}
          label='Adresse'
        />
        <EditButton />
        <DeleteButton label='' mutationMode='pessimistic' confirmTitle='' />
      </Datagrid>
    </List>
  );
};

interface TeamFormProps {
  type: 'create' | 'edit';
}

const CompetitionLocationsForm = (props: TeamFormProps) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const [create] = useCreate();
  const [update] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      try {
        const newData = values;
        if (props.type == 'create') {
          await create('competition_locations', { data: newData }, { returnPromise: true });
          notify('ra.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        } else {
          await update('competition_locations', { id: values.id, data: newData }, { returnPromise: true });
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });
        }
        redirect('list', 'competition_locations');
      } catch (error: any) {
        if (error.body) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body;
        }
      }
    },
    [create, notify, redirect, update, props.type],
  );

  return (
    <SimpleForm onSubmit={save} toolbar={<SimpleFormToolbar redirectTo='list' resource='competition_locations' />}>
      <div className='formContainer'>
        <TextInput
          source='name'
          label='Nom'
          style={{ marginRight: '20px' }}
          isRequired
          parse={(v: string) => v}
          placeholder='Ex : Stade Léo Lagrange'
        />
      </div>
      <p className='formTitle'>Adresse postale</p>
      <div className='formContainer'>
        <TextInput
          source='address.street'
          label='N° et Rue'
          parse={(v: string) => v}
          style={{ marginRight: '20px' }}
          isRequired
        />

        <TextInput
          source='address.city'
          label='Ville'
          parse={(v: string) => v}
          style={{ marginRight: '20px' }}
          isRequired
        />
        <TextInput
          source='address.zipcode'
          label='Code postal'
          parse={(v: string) => v}
          style={{ marginRight: '20px' }}
          isRequired
        />
        <TextInput
          source='address.additionalInfo'
          label='Information additionnelles'
          parse={(v: string) => v}
          style={{ marginRight: '20px' }}
        />

        <TextInput source='address.state' label='Région' parse={(v: string) => v} style={{ marginRight: '20px' }} />
        <TextInput
          source='address.country'
          label='Pays'
          parse={(v: string) => v}
          style={{ marginRight: '20px' }}
          isRequired
        />
      </div>
    </SimpleForm>
  );
};

export const CompetitionLocationsEdit = () => (
  <Edit>
    <CompetitionLocationsForm type='edit' />
  </Edit>
);

export const CompetitionLocationsCreate = () => (
  <Create>
    <CompetitionLocationsForm type='create' />
  </Create>
);
