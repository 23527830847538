import React, { useEffect } from 'react';
import { Menu, MenuProps, useGetOne, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import BookIcon from '@mui/icons-material/Book';
import RoomIcon from '@mui/icons-material/Room';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DescriptionIcon from '@mui/icons-material/Description';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import EuroIcon from '@mui/icons-material/Euro';
import { useResourcePath } from '../services/hooks';
import './AdminStyle.scss';
import { Club, ValidationStatus } from '../models/club';

export const AdminLayoutMenu = (props: MenuProps) => {
  const redirect = useRedirect();
  const resourcePath = useResourcePath();
  const location = useLocation();

  const clubId = resourcePath.resourcePath.value.find((v) => v.resource === 'clubs')?.id;

  const { data: club, isSuccess } = useGetOne<Club>('clubs', { id: clubId || '' }, { enabled: clubId ? true : false });

  // if club validated status changed, redirect to relevant page
  useEffect(() => {
    if (
      location.pathname !== '/home' &&
      isSuccess &&
      resourcePath.resourcePath.value.length === 1 &&
      club?.validationStatus !== ValidationStatus.VALIDATED
    ) {
      resourcePath.clearResourcePath();
      redirect('/clubs');
    } else if (isSuccess && location.pathname === '/home' && club?.validationStatus === ValidationStatus.VALIDATED) {
      redirect('/dashboard');
    }
  }, [club, resourcePath, redirect, isSuccess, location]);

  const RootPov = () => {
    return (
      <div key='root' style={{ marginTop: '80px' }}>
        <Menu.Item to='/clubs' primaryText='Clubs' leftIcon={<FolderSharedIcon />} />
      </div>
    );
  };
  const ClubsPov = () => {
    return (
      <div key='clubs' style={{ marginTop: '80px', width: 'inherit' }}>
        <Menu.Item to='/dashboard' primaryText='Tableau de bord' leftIcon={<BookIcon />} />
        <Menu.Item to='/licensees' primaryText='Mes licenciés' leftIcon={<SwitchAccountIcon />} />
        <Menu.Item to='/teams' primaryText='Équipes' leftIcon={<GroupsIcon />} />
        <Menu.Item to='/competition_locations' primaryText='Lieux de compétitions' leftIcon={<RoomIcon />} />
        <Menu.Item to='/competitions' primaryText='Matchs' leftIcon={<EmojiEventsIcon />} />
        <Menu.Item to='/incomes' primaryText='Revenus' leftIcon={<EuroIcon />} />
        <Menu.Item to='/billing' primaryText='Facturation' leftIcon={<DescriptionIcon />} />
        <Menu.Item
          to='/test_competitions'
          primaryText='Matchs de test'
          leftIcon={<VideoSettingsIcon />}
          sx={{ marginTop: 5, paddingTop: 2, borderTop: '3px solid #64DCBE' }}
        />
      </div>
    );
  };

  return (
    <Menu
      {...props}
      sx={{
        '&.RaMenu-open': {
          width: '210px',
          height: '100vh',
          // height of AppBar
          marginTop: '-64px',
          '@media (max-width: 1224px)': { width: '60px' },
        },
      }}
    >
      {club?.validationStatus === ValidationStatus.VALIDATED ? <ClubsPov /> : <RootPov />}
      <div className='usefullLinks'>
        <a
          href='https://partenaires.champs.fr/communication/tutoriels-portail-partenaire/'
          target='_blank'
          rel='noreferrer'
        >
          Tutoriel
        </a>
        {' - '}
        <a href='https://partenaires.champs.fr/communication/faq-portail-partenaires/' target='_blank' rel='noreferrer'>
          FAQ
        </a>
        {' - '}
        <a rel="noreferrer" href='https://partenaires.champs.fr/communication/conditions-generales-de-services-de-la-plateforme-champs/' target='_blank'>CGS</a> <br />
        <a href='https://www.champs.fr/demandez-une-demonstration/' target='_blank' rel='noreferrer'>
          Contactez-nous
        </a>
      </div>
    </Menu>
  );
};
